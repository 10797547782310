import React from 'react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { createRoot } from 'react-dom/client';

import App from './App';

LicenseInfo.setLicenseKey(
  '04d402c316ca080d2c36c0c769669fb6Tz0xMDUwNTIsRT0xNzY3NDUwNTAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
);

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
